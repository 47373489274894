<template>
    <div>
        <input v-model="searchQuery" placeholder="Enter text to search">
        <button @click="searchText">Search</button>
        <div style="height: 1000px;"></div>
        <el-collapse v-model="activeNames">
            <el-collapse-item title="Section 1" name="1">
                <div v-html="highlightText(content1)"></div>
            </el-collapse-item>
            <el-collapse-item title="Section 2" name="2">
                <div v-html="highlightText(content2)"></div>
            </el-collapse-item>
            <el-collapse-item title="Section 3" name="3">
                <div v-html="highlightText(content3)"></div>
            </el-collapse-item>
            <el-collapse-item title="Section 4" name="4">
                <div v-html="highlightText(content4)"></div>
            </el-collapse-item>
            <el-collapse-item title="Section 5" name="5">
                <div v-html="highlightText(content5)"></div>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            searchQuery: '',
            content1: `<div style="margin: 48px 52px 0;">
                        <div style="font-size: 16px;">
                            You can reach one of our Pet Owner Experience Advisors toll-free at 1-800-592-6687. We are
                            available
                            Monday through Friday, 8:00 AM – 4:30 PM CT. .
                        </div>
                        <div style="font-size: 16px;">
                            In the US, you can reach one of our devoted customer service reps toll-free at 1-800-672-4399
                            anytime,
                            day or night -we're available 24/7.
                        </div>
                        <div style="font-size: 16px;">
                            You can also chat or email service@GIGWI.com and we'll get right back to you with a response
                        </div>
                        <div style="border-bottom: 1px solid #000;"></div>
                        <div v-html="highlightText(content2)" class="ques">
                        </div>
                        <div style="font-size: 16px;">We do not have a minimum or maximum order value. All orders qualify
                            for free shipping and handling.
                        </div>
                        <el-divider></el-divider>
                        <div v-html="highlightText(content3)" class="ques">
                        </div>
                        <div style="font-size: 16px;">
                            Once you have placed an order, you will not be able to add or remove products. Please see our
                            return
                            policy in our Terms and Conditions or contact us and one of our advisors will be happy to assist
                            you.
                        </div>
                        <el-divider></el-divider>
                        <div v-html="highlightText(content4)" class="ques">
                        </div>
                        <div style="font-size: 16px;">
                            When an item is out of stock, you can sign up to be notified when it’s available again. Go to
                            the item
                            page, enter your email address, and Click “Notify Me” to sign up.
                        </div>
                        <div style="font-size: 16px;">
                            You can sign up for notifications for as many items as you’d like. We’ll send one email
                            notification for
                            each item that’s back in stock. Signing up for notifications doesn’t
                        </div>
                        <div style="font-size: 16px;">
                            reserve or guarantee item availability.
                        </div>
                        <el-divider></el-divider>
                        <div v-html="highlightText(content5)" class="ques">
                        </div>
                        <p style="font-size: 16px;">You 'd better contact our sales managers at first. In some country maybe
                            we already have sole agency.If
                            it is vancancy in there.You should submit your bussiness</p>
                        <p style="font-size: 16px;">information via register on our wesite. After we check it and passing
                            the examiation.we will sign a
                            delegater
                            aggrement with you..........</p>
                        <el-divider></el-divider>
                        <div v-html="highlightText(content6)" class="ques">
                        </div>
                        <p style="font-size: 16px;">We only open the membership for our delegator or shoper. Can not for
                            termaial consumer now ........</p>
                        <el-divider></el-divider>
                    </div>`,
            content2: 'Here is more content that can be searchable.',
            content3: `<p style="font-size: 16px;">We apologize for any damaged or incorrect product you may have received
                            and want to make it right. Please contact us and one of our advisors will be happy to</p>
                        <p style="font-size: 16px;">assist you with a refund or replacement.</p><p style="font-size: 16px;">We apologize for any damaged or incorrect product you may have received
                            and want to make it right. Please contact us and one of our advisors will be happy to</p>
                        <p style="font-size: 16px;">assist you with a refund or replacement.</p>`,
            content4: `<p style="font-size: 16px;">We apologize for any damaged or incorrect product you may have received
                            and want to make it right. Please contact us and one of our advisors will be happy to</p>
                        <p style="font-size: 16px;">assist you with a refund or replacement.</p><p style="font-size: 16px;">We apologize for any damaged or incorrect product you may have received
                            and want to make it right. Please contact us and one of our advisors will be happy to</p>
                        <p style="font-size: 16px;">assist you with a refund or replacement.</p>`,
            content5: `<p style="font-size: 16px;">We apologize for any damaged or incorrect product you may have received
                            and want to make it right. Please contact us and one of our advisors will be happy to</p>
                        <p style="font-size: 16px;">assist you with a refund or replacement.</p><p style="font-size: 16px;">We apologize for any damaged or incorrect product you may have received
                            and want to make it right. Please contact us and one of our advisors will be happy to</p>
                        <p style="font-size: 16px;">assist you with a refund or replacement.</p>`,
            activeNames: ['1'],  // 用来控制哪些折叠项是开启的
        };
    },
    methods: {
        highlightText(text) {
            if (!this.searchQuery) return text;
            const regex = new RegExp(this.searchQuery, 'gi');
            let isFirstMatch = true; // 标记第一个匹配项，以便后续滚动
            return text.replace(regex, (match) => {
                const result = `<span class="highlight">${match}</span>`;
                if (isFirstMatch) {
                    isFirstMatch = false;
                    return `<span class="highlight" id="first-match">${match}</span>`;
                }
                return result;
            });
        },
        searchText() {
            this.resetSearch();
            // 检查每个内容块是否包含搜索关键词
            const contents = [this.content1, this.content2,this.content3, this.content4, this.content5];
            contents.forEach((content, index) => {
                if (content.toLowerCase().includes(this.searchQuery.toLowerCase())) {
                    this.activeNames.push(String(index + 1));  // 添加到 activeNames 以展开对应的折叠项
                }
            });

            this.$nextTick(() => {
                const element = document.getElementById('first-match');
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            });
        },
        resetSearch() {
            this.activeNames = [];  // 重置折叠状态
        }
    }
};
</script>

  
<style>
 .highlight {
    background-color: yellow;
}
</style>
  